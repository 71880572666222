
import { defineComponent } from "vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-aside-primary",
  components: {
    KTQuickLinksMenu,
    KTNotificationsMenu,
    KTUserMenu,
  },
  setup() {
    const router = useRouter();
    const Dashboard = () => {
      router.push({ name: "dashboard" });
    };
    return {
      Dashboard,
    };
  },
  data(): {
    Auth: any;
    Code: any;
    List: Array<any>;
  } {
    return {
      Auth: {},
      Code: "",
      List: [],
    };
  },
  methods: {
    signOut() {
      const router = useRouter();
      var yes = confirm("確定登出嗎？");
      if (yes) {
        localStorage.removeItem("id_token");
        localStorage.removeItem("Auth");
        location.href = "/#/sign-in";
      }
    },
  },
  created() {
    let str: any;
    setTimeout(() => {
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      this.Code = this.Auth.Role.Code;
    }, 500);
  },
});
