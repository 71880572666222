const DocMenuConfig = [
  {
    heading: "戰情中台",
    route: "/system",
    type: "EZ-DMO",
    permissions: 0,
    pages: [
      {
        sectionTitle: "工單進度回報",
        route: "/system/Ht-Tr-parameter/List",
        type: 0,
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        sectionTitle: "機台儀表板",
        type: 0,
        route: "/system/ProductionLine/Machine",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        sectionTitle: "料貨號儀表板",
        type: 1,
        route: "/system/ProductionLine/Material",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        sectionTitle: "研磨參數模擬",
        type: 0,
        route: "/system/ProductionLine/CMM",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
    ],
  },
  {
    heading: "工單與來料管理",
    route: "/system",
    type: "Ht-Tr-parameter",
    permissions: 0,
    pages: [
      {
        sectionTitle: "工單進度回報",
        route: "/system/Ht-Tr-parameter/List",
        type: 0,
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        sectionTitle: "來料分析列表",
        type: 1,
        route: "/system/Ht-Tr-technology/Add",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "進料檢驗管理",
    route: "/system",
    type: "IQC",
    permissions: 0,
    pages: [
      {
        sectionTitle: "進料檢驗列表",
        type: 0,
        route: "/system/IQC/List",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "料貨號管理",
    route: "/system",
    type: "Modal",
    permissions: 1,
    pages: [
      {
        sectionTitle: "料貨號列表",
        route: "/system/Modal/List",
        type: 1,
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        sectionTitle: "新增料貨號",
        route: "/system/Modal/Add",
        type: 1,
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "生產線即時監控",
    route: "/system",
    type: "ProductionLine",
    permissions: 1,
    pages: [
      {
        sectionTitle: "機台儀表板",
        route: "/system/ProductionLine/Machine",
        type: 0,
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        sectionTitle: "料貨號儀表板",
        route: "/system/ProductionLine/Material",
        type: 1,
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
    ],
  },
  // {
  //   heading: "來料分析管理",
  //   route: "/system",
  //   type: "Ht-Tr-technology",
  //   permissions: 1,
  //   pages: [
  //     {
  //       sectionTitle: "來料分析列表",
  //       type: 1,
  //       route: "/system/Ht-Tr-technology/Add",
  //       svgIcon: "media/icons/duotune/general/gen022.svg",
  //       fontIcon: "bi-archive",
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
