
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";

export default defineComponent({
  name: "kt-menu",
  props: {
    type: String,
  },

  components: {},
  data(): {
    Auth: any;
    List: Array<any>;
  } {
    return {
      Auth: {},
      List: [],
    };
  },
  created() {
    this.List = [];
    let str: any;
    setTimeout(() => {
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      switch (this.Auth.Role.Code) {
        case "root":
          this.List = MainMenuConfig.filter(item => {
            return item;
          });
          break;
        case "admin":
          this.List = MainMenuConfig.filter(item => {
            return item.permissions == 0;
          });
          break;
      }
    }, 500);
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = text => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
